<template>
    <div>
        <div class="login-page">
            <div class="login-form">
                <div class="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
                    <div class="text-center mb-4">
                        <img class="transcrypt-logo-login-and-register mb-5"
                             src="/assets/remittance-assets/img/transcrypt-logo-colour.png"/>
                    </div>
                    <br>
                    <br>
                    <h2 class="text-center">
                        Are you sure you want to logout ?
                    </h2>

                    <br>
                    <br>
                    <br>
                    <button @click="confirmLogout()" class="btn btn-warning btn-block mb-2">
                        Logout
                    </button>
                    <button @click="goBack()" class="btn btn-success btn-block mb-2">
                        Go Back
                    </button>

                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    export default {
        name: 'Login',
        data() {
            return {
                // imageSrc: "",
                // emailLogin: "",
                // passwordLogin: "",
                //
                // validationEmailLoginError: false,
                // validationPasswordLoginError: false,
                // validationLoginError: false,
            }
        },
        watch: {
            $route() {

            },

        },
        mounted() {
        },
        metaInfo: {
            title: 'TransCrypt - Logout | Send Money Faster with Cryptocurrency',
        },
        methods: {
            ...mapActions([
                "authLogout"
            ]),
            confirmLogout() {
                this.authLogout().then(res => {
                    if (this.debugConsole) console.log("logout the current logged in user", res.data);

                    // reset current app state
                    this.$store.commit("USER_LOGOUT");
                    
                    // remove localstorage data
                    localStorage.removeItem('vuex');

                    this.$router.push('/account/login');

                }).catch(err => {
                    if (this.debugConsole) console.log("Error in logut the current loggedi in user", err);
                })
            },
            goBack(){
                this.$router.back();
            }


        }
    }
</script>
